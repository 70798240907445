import React, { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import calender from "../../../../assets/images/calender.svg";
import AtomLoading from "./../../../../components/loding-indicator";
import { ConfigProvider, Table, Modal, Tag } from "antd";
import "./../students.styles.scss";
import { useCookies } from "react-cookie";
import {
  getListOfferingClasses,
  getMyActivePlan,
} from "./../../../../redux/student/student.action";
import "react-calendar/dist/Calendar.css";
import EmptyData from "../../../../components/no-data";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { DateRange } from "react-date-range";
import hexToRGBA from "../../../../helper/hexToRbga";
import Button from "../../../../components/Button/button.component";
import { getStudentPlan } from "../../../../redux/owner/owner.action";
import ModalDetailClass from "./modal-detail-class";
import ModalSuccessBookingClass from "../../../../components/Modal/success-booking-class";
import { getDisbursementSetting } from "../../../../services/payment";
import { sendGetRequest } from "../../../../services/students";
import { getLocation } from "../../../../redux/location/location.action";

const typeBoolean = {
  TRUE: true,
  FALSE: false,
};

const ClassIndex = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentDate = new Date();
  let last = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [cookies, setCookies] = useCookies(["user"]);
  const [isModalOpenDateRange, setIsModalDatOpeRange] = useState(false);
  const [isModalOpenDetailClass, setIsModalOpenDetailClass] = useState(false);
  const [prosessEndroll, setProsessEndroll] = useState(false);
  const [confirmMethod, setConfirmMethod] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentGateway, setPaymentGateway] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [selectedClassDetail, setSelectedClassDetail] = useState({});
  const [state, setState] = useState([
    {
      startDate: currentDate,
      endDate: last,
      key: "selection",
    },
  ]);
  const [locationId, setLocationId] = useState([]);

  const [propsPayment, setPropsPayment] = useState();
  const [openStripe, setOpenStripe] = useState(typeBoolean.FALSE);

  const { payment_detail, student_plan } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { public_classes, class_list, isLoadingClasses, active_plan, status } =
    useSelector((state) => state.student);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const colorTheme = studio_detail?.styles?.header_color;
  const student_id = props.location.state?.student_id;
  const fromOwner = props.location.state?.fromOwner;
  const isBrowseClasses = props.location.state?.isBrowseClasses;
  const class_offerings = isBrowseClasses ? public_classes : class_list;
  const subdomain = window.location.host.split(".")[0];

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const activePlanData = fromOwner ? student_plan?.data : active_plan;
  const isSettlement = propsPayment?.payment?.payment_status === "settlement";

  useEffect(() => {
    handleGetDisbursementSetting();
  }, []);

  useEffect(() => {
    if (fromOwner) {
      dispatch(
        getListOfferingClasses(
          user_token,
          studio_id,
          `${moment(state[0].startDate).format("YYYY-MM-DD")}&end_date=${moment(
            state[0].endDate
          ).format("YYYY-MM-DD")}&student_id=${student_id}`
        )
      );
      dispatch(
        getStudentPlan(user_token, studio_id, student_id, "status=active")
      );
    } else {
      dispatch(
        getListOfferingClasses(
          user_token,
          studio_id,
          `${moment(state[0].startDate).format("YYYY-MM-DD")}&end_date=${moment(
            state[0].endDate
          ).format("YYYY-MM-DD")}`
        )
      );
      dispatch(getMyActivePlan(user_token, studio_id));
    }
  }, [
    dispatch,
    fromOwner,
    state,
    student_id,
    studio_id,
    user_token,
    selectedDate,
    isSettlement,
  ]);

  const showModalDateRange = () => {
    setIsModalDatOpeRange(true);
  };

  const handleOkDateRange = () => {
    setIsModalDatOpeRange(false);
  };

  const handleCancelDateRange = () => {
    setIsModalDatOpeRange(false);
  };

  const showModalDetailClass = () => {
    setIsModalOpenDetailClass(true);
  };

  const handleCancelDetailClass = () => {
    setIsModalOpenDetailClass(false);
    setProsessEndroll(false);
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const columns = [
    {
      title: "Class Name",
      dataIndex: "class_name",
      key: "class_name",
      render: (text, record) => {
        return <p className="font-black">{text}</p>;
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacher",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return text ? (
          <Tag color="#27AE60">Online</Tag>
        ) : (
          <Tag color="#2D9CDB">Onsite</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        if (record.user_has_booked_class) {
          return (
            <div
              style={{ color: colorTheme }}
              className="button flex justify-center items-center"
            >
              Register
            </div>
          );
        }
        return (
          <Button
            onClick={() => {
              setSelectedClassDetail(record);
              showModalDetailClass();
              setProsessEndroll(true);
            }}
            type="primary"
            title="Enroll Now"
            style={{
              backgroundColor: colorTheme || "#E26851",
              borderRadius: 0,
            }}
          />
        );
      },
    },
  ];

  const onChangeRadio = (e) => {
    setPaymentMethod(e.target.value);
  };

  // const handleStripe = (params) => {
  //   setPropsPayment(params);
  //   setOpenStripe(typeBoolean.TRUE);
  // };

  const handleGetDisbursementSetting = async () => {
    try {
      const { data } = await getDisbursementSetting(user_token, studio_id);
      const { payment_gateway, recipient_bank_code } = data.data;

      setPaymentGateway(payment_gateway);
      setBankCode(recipient_bank_code);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusOrder = async () => {
    try {
      const { data } = await sendGetRequest(
        user_token,
        studio_id,
        `/orders/${propsPayment?._id}`
      );
      const { payment_status } = data?.data?.payment;
      if (payment_status === "settlement") {
        setPropsPayment();
      }
      setPropsPayment(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (propsPayment?._id) {
      const intervalId = setInterval(() => {
        getStatusOrder();
      }, 60000); // 60 seconds

      // Cleanup the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [propsPayment?._id]);

  return (
    <OrganismLayout isBrowseClasses={isBrowseClasses} maxWidth="42rem">
      {isLoadingClasses ? (
        <AtomLoading />
      ) : (
        <div className="px-8 md:px-2">
          <div className="flex justify-between">
            <h2 className="text-zinc-800 font-bold">Class Offerings</h2>
            <div className="d-flex align-items-center">
              <img alt="" src={calender} width={18} />
              <span
                onClick={showModalDateRange}
                className=" pointer custom-hover ms-2"
              >
                {` ${moment(state[0].startDate).format(
                  "DD MMM, YYYY"
                )} - ${moment(state[0].endDate).format("DD MMM, YYYY")}`}
              </span>
            </div>
          </div>

          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBorderRadius: 0,
                  headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
                },
              },
            }}
          >
            <div className="gap-6">
              {class_list?.length > 0 ? (
                class_list.map((data, idx) => {
                  const dataClass = data?.class_schedules.map((el, i) => {
                    return {
                      key: i,
                      class_name: el.class_name,
                      time: el.class_start_time + " - " + el.class_end_time,
                      teacher: el.teachers.join(),
                      type: el.is_online,
                      ...el,
                    };
                  });
                  return (
                    <div key={idx} className="py-9">
                      <div
                        style={{
                          borderColor: colorTheme,
                        }}
                        className="border border-opacity-30"
                      >
                        <div className="px-2 py-4 flex justify-between items-center font-semibold">
                          <div>{days[moment(data?.class_date).day()]}</div>
                          <div>
                            {moment(data?.class_date).format("DD MMMM, YYYY")}
                          </div>
                        </div>

                        <Table
                          className="min-w-100"
                          columns={columns}
                          size="middle"
                          dataSource={dataClass}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyData />
              )}
            </div>
          </ConfigProvider>
        </div>
      )}

      <Modal
        footer={null}
        open={isModalOpenDateRange}
        onOk={handleOkDateRange}
        onCancel={handleCancelDateRange}
      >
        <div className="py-2 text-center">
          <DateRange
            className="shadow-sm mb-3"
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <div className="d-flex justify-content-center ">
            <Button
              type="primary"
              title="Close"
              onClick={handleCancelDateRange}
              style={{
                borderRadius: 0,
                backgroundColor: colorTheme,
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={isModalOpenDetailClass}
        onCancel={handleCancelDetailClass}
        footer={null}
        width={700}
      >
        <ModalDetailClass
          setModalSuccess={setModalSuccess}
          propsPayment={propsPayment}
          setPropsPayment={setPropsPayment}
          bankCode={bankCode}
          paymentGateway={paymentGateway}
          prosessEndroll={prosessEndroll}
          setProsessEndroll={setProsessEndroll}
          selectedClassDetail={selectedClassDetail}
          colorTheme={colorTheme}
          locationId={locationId}
          setLocationId={setLocationId}
          confirmMethod={confirmMethod}
          activePlanData={activePlanData}
          onChangeRadio={onChangeRadio}
          paymentMethod={paymentMethod}
          fromOwner={fromOwner}
          student_id={student_id}
          setIsModalOpenDetailClass={setIsModalOpenDetailClass}
          setSelectedClassDetail={setSelectedClassDetail}
          setPaymentMethod={setPaymentMethod}
          setConfirmMethod={setConfirmMethod}
        />
      </Modal>

      <ModalSuccessBookingClass
        propsPayment={propsPayment}
        open={modalSuccess}
        colorTheme={colorTheme}
        setOpen={setModalSuccess}
        selectedClassDetail={selectedClassDetail}
      />
    </OrganismLayout>
  );
};

export default ClassIndex;
