import { toast } from "react-toastify";
import axios from "../../axiosInstance";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import StudentActionTypes from "./student.types";

export const setStatusDefault = () => {
  return (dispatch) => {
    dispatch({
      type: StudentActionTypes.SET_STATUS_DEFAULT,
    });
  };
};

// Dasboard Student
const getActivePlanUrl = "/students/plans?status=";
const getClassesUrl = "/students/classes?";

export const getActivePlan = (token, studio_id, params) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      dispatch({
        type: StudentActionTypes.GET_ACTIVE_PLAN,
      });

      const res = await axios.get(`${getActivePlanUrl}${params}`, config);

      dispatch({
        type: StudentActionTypes.GET_ACTIVE_PLAN_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_ACTIVE_PLAN_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getClasses = (token, studio_id, params) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      dispatch({
        type: StudentActionTypes.GET_CLASSES,
      });

      const res = await axios.get(`${getClassesUrl}${params}`, config);

      dispatch({
        type: StudentActionTypes.GET_CLASSES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_CLASSES_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

// Profile
const getOrUpdateProfileUrl = "/profiles";
const changeRoleUrl = "/profiles/roles";

export const getProfile = (token, studio_id) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      dispatch({
        type: StudentActionTypes.GET_PROFILE,
      });

      const res = await axios.get(getOrUpdateProfileUrl, config);
      dispatch({
        type: StudentActionTypes.GET_PROFILE_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_PROFILE_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const updateProfile = (token, studio_id, params) => {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.UPDATE_PROFILE,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(getOrUpdateProfileUrl, formData, config);
      dispatch({
        type: StudentActionTypes.UPDATE_PROFILE_SUCCESS,
      });

      toast.success("Profile Updated Successfully!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.UPDATE_PROFILE_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getUserRole = (token, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.GET_USER_ROLE,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(changeRoleUrl, config);
      dispatch({
        type: StudentActionTypes.GET_USER_ROLE_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_USER_ROLE_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

// Change email
const requestOtpUrl = "/profiles/otp";
const verifyOtpUrl = "/profiles/otp/verify";
const changeEmailUrl = "/profiles/email";

export const requestOtp = (token, studio_id, email) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.REQUEST_OTP_CHANGE_EMAIL,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(
        requestOtpUrl,
        {
          email: email,
        },
        config
      );

      dispatch({
        type: StudentActionTypes.REQUEST_OTP_CHANGE_EMAIL_SUCCESS,
        payload: email,
      });

      toast.info("OTP has been sent to your e-mail!");
      return res;
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.REQUEST_OTP_CHANGE_EMAIL_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const setStatusOtpDefault = () => {
  return (dispatch) => {
    dispatch({
      type: StudentActionTypes.REQUEST_OTP_CHANGE_EMAIL_DEFAULT,
    });
  };
};

export const setStatusVerifyEmailDefault = () => {
  return (dispatch) => {
    dispatch({
      type: StudentActionTypes.VERIFY_OTP_EMAIL_DEFAULT,
    });
  };
};

export const verifyOtpChangeEmail = (token, studio_id, params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.VERIFY_OTP_EMAIL,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(verifyOtpUrl, params, config);
      dispatch({
        type: StudentActionTypes.VERIFY_OTP_EMAIL_SUCCESS,
        payload: res.data.message,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.VERIFY_OTP_EMAIL_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const changeEmail = (token, studio_id, params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.CHANGE_EMAIL,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(changeEmailUrl, params, config);

      dispatch({
        type: StudentActionTypes.CHANGE_EMAIL_SUCCESS,
        payload: res.data.data,
      });

      toast.success("E-mail Changed Successfully!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.CHANGE_EMAIL_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

// Plan
const getListPlanTransactionUrl = "/orders/plan";
const getListOfferingPlansUrl = "/offering-plans";
const getDetailPlanTransactionUrl = "/orders/";
const buyOrDeactivatePlanUrl = "/orders";
const callbackMidtransUrl = "/callback-midtrans";

export const getListPlanTransaction = (token, studio_id, url) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      dispatch({
        type: StudentActionTypes.GET_LIST_PLAN_TRANSACTION,
      });

      const res = await axios.get(url, config);

      dispatch({
        type: StudentActionTypes.GET_LIST_PLAN_TRANSACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_LIST_PLAN_TRANSACTION_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getListOfferingPlans = (token, studio_id) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      dispatch({
        type: StudentActionTypes.GET_LIST_OFFERING_PLAN,
      });

      const res = await axios.get(getListOfferingPlansUrl, config);

      dispatch({
        type: StudentActionTypes.GET_LIST_OFFERING_PLAN_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_LIST_OFFERING_PLAN_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const callbackMidtrans = (params, studio_id) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      dispatch({
        type: StudentActionTypes.CALLBACK_MIDTRANS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state.use.user.token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(callbackMidtransUrl, params, config);

      dispatch({
        type: StudentActionTypes.CALLBACK_MIDTRANS_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.CALLBACK_MIDTRANS_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const deactivatePlan = (token, studio_id, params, order_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.DEACTIVE_PLAN,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(
        `orders/plans/${order_id}/deactivate`,
        params,
        config
      );

      dispatch({
        type: StudentActionTypes.DEACTIVE_PLAN_SUCCESS,
        payload: res.data.data,
      });

      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.DEACTIVE_PLAN_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

// Buy Plan
export const directBuyManual = (token, studio_id, params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.MANUAL,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(buyOrDeactivatePlanUrl, params, config);

      dispatch({
        type: StudentActionTypes.MANUAL_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.MANUAL_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getDetailPlanTransaction = (token, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.GET_DETAIL_PLAN_TRANSACTION,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${getDetailPlanTransactionUrl}${id}`,
        config
      );

      dispatch({
        type: StudentActionTypes.GET_DETAIL_PLAN_TRANSACTION_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_DETAIL_PLAN_TRANSACTION_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

// Class
const getListOfferingClassesUrl = "/offering-class?start_date=";
const getDetailOfferingClassesUrl = "/offering-class?class_schedule_id=";
const getPublicListOfferingClassesUrl = "/public-offering-classes?";
const myActivePlansUrl = "/orders/plan?plan_status=active";
const myActiveClasssUrl = "/orders/plan";
const classPaymentUrl = "/orders";
const paymentVerificationUrl = "/payment-verifications";

export const getListOfferingClasses = (token, studio_id, start_date) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      dispatch({
        type: StudentActionTypes.GET_LIST_OFFERING_CLASSES,
      });

      const res = await axios.get(
        `${getListOfferingClassesUrl}${start_date}`,
        config
      );

      dispatch({
        type: StudentActionTypes.GET_LIST_OFFERING_CLASSES_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_LIST_OFFERING_CLASSES_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getDetailOfferingClasses = (token, studio_id, class_id) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      dispatch({
        type: StudentActionTypes.GET_DETAIL_OFFERING_CLASSES,
      });

      const res = await axios.get(
        `${getDetailOfferingClassesUrl}${class_id}`,
        config
      );

      dispatch({
        type: StudentActionTypes.GET_DETAIL_OFFERING_CLASSES_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_DETAIL_OFFERING_CLASSES_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getPublicListOfferingClasses = (subdomain, params) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          subdomain: subdomain,
        },
      };

      dispatch({
        type: StudentActionTypes.GET_PUBLIC_LIST_OFFERING_CLASSES,
      });

      const res = await axios.get(
        `${getPublicListOfferingClassesUrl}${params}`,
        config
      );

      dispatch({
        type: StudentActionTypes.GET_PUBLIC_LIST_OFFERING_CLASSES_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_PUBLIC_LIST_OFFERING_CLASSES_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getPublicClasses = (subdomain) => {
  const config = {
    headers: {
      "content-type": "application/json",
      subdomain: subdomain,
    },
  };

  const res = axios.get(`/public/classes`, config);

  return res;
};

export const getPublicStudioLocation = (subdomain) => {
  const config = {
    headers: {
      "content-type": "application/json",
      subdomain: subdomain,
    },
  };

  const res = axios.get(`/locations/studio/public`, config);

  return res;
};

export const getMyActivePlan = (token, studio_id) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      dispatch({
        type: StudentActionTypes.GET_MY_ACTIVE_PLAN,
      });

      const res = await axios.get(myActivePlansUrl, config);

      dispatch({
        type: StudentActionTypes.GET_MY_ACTIVE_PLAN_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_MY_ACTIVE_PLAN_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getMyActiveClass = (token, studio_id) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      dispatch({
        type: StudentActionTypes.GET_MY_ACTIVE_CLASS,
      });

      const res = await axios.get(myActiveClasssUrl, config);

      dispatch({
        type: StudentActionTypes.GET_MY_ACTIVE_CLASS_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_MY_ACTIVE_CLASS_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const cancelClass = (token, studio_id, params, order_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.CANCEL_CLASS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(
        `/order/${order_id}/class/cancel`,
        params,
        config
      );

      dispatch({
        type: StudentActionTypes.CANCEL_CLASS_SUCCESS,
        payload: res.data.data,
      });

      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.CANCEL_CLASS_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

// Class Payment
export const bookClass = async (token, params, studio_id) => {
  try {
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "Studio-Id": studio_id,
      },
    };

    const res = await axios.post(classPaymentUrl, params, config);
    toast.success("Success!");
    return res;
  } catch (error) {
    toast.error(<GenerateErrorMessage error={error} />);
  }
};

export const activeClass = (token, params, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.ACTIVE_CLASS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(classPaymentUrl, params, config);

      dispatch({
        type: StudentActionTypes.ACTIVE_CLASS_SUCCESS,
        payload: res.data.data,
      });
      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.ACTIVE_CLASS_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getDetailClassTransaction = (token, studio_id, payment_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.GET_DETAIL_CLASS_TRANSACTION,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(`${classPaymentUrl}/${payment_id}`, config);

      dispatch({
        type: StudentActionTypes.GET_DETAIL_CLASS_TRANSACTION_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.GET_DETAIL_CLASS_TRANSACTION_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

// Payment Verification
export const paymentVerification = (token, studio_id, params) => {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return async (dispatch) => {
    try {
      dispatch({
        type: StudentActionTypes.PAYMENT_VERIFICATION,
      });

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(paymentVerificationUrl, formData, config);

      dispatch({
        type: StudentActionTypes.PAYMENT_VERIFICATION_SUCCESS,
        payload: res.data.data,
      });

      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudentActionTypes.PAYMENT_VERIFICATION_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const setMsgPayment = (payload) => ({
  type: StudentActionTypes.SET_MSG_PAYMENT,
  payload,
});
