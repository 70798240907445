import React from "react";
import google from "./../../assets/images/google_icon.png";
import facebook from "./../../assets/images/facebook_icon.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { Form } from "react-bootstrap";
import TextInput from "../../components/TextInput/text-input.component";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/button.component";

const StudioLoginForm = ({
  colorTheme,
  email,
  handleClick,
  handleChange,
  responseSocialAuth,
  hideBrowseClasses = false,
}) => {
  const history = useHistory();

  const { isLoading, studio_detail } = useSelector((state) => state.user);

  return (
    <div className="login-content ">
      <h1 className=" mt-4 mb-2   text-3xl">Sign in</h1>
      <div className="text-xs  my-5">
        If you don’t have an account register <br /> You can{" "}
        <span
          style={{
            color: colorTheme || studio_detail?.styles?.header_color,
          }}
          className=" font-medium cursor-pointer hover:opacity-50"
          onClick={() => history.push("/register-student")}
        >
          Register here !
        </span>
      </div>

      <Form className="w-100" onSubmit={handleClick}>
        <TextInput
          required
          autoFocus
          label="Email"
          placeholder="johndoe@gmail.com"
          onChange={handleChange}
          value={email}
        />
        <Button
          buttonType="submit"
          title="CONTINUE"
          type="primary"
          className="mt-5"
          style={{
            width: "100%",
            backgroundColor:
              colorTheme || studio_detail?.styles?.header_color || "#E26851",
            borderRadius: 0,
          }}
          isLoading={isLoading}
        />
      </Form>

      <div className="w-full flex flex-col justify-center mb-24">
        <span className="custom-form-label my-2 text-center text-[#B5B5B5]">
          or continue with
        </span>
        <div className="flex items-center justify-center">
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => {
              return (
                <img
                  alt="logo"
                  onClick={renderProps.onClick}
                  style={{
                    height: "25px",
                    width: "25px",
                    marginRight: "10px",
                  }}
                  src={google}
                  className="cursor-pointer"
                />
              );
            }}
            onSuccess={(res) => responseSocialAuth("google", res)}
            onFailure={(res) => responseSocialAuth("google", res)}
          />
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            size="small"
            render={(renderProps) => (
              <img
                className="cursor-pointer"
                onClick={renderProps.onClick}
                style={{
                  height: "25px",
                  width: "25px",
                  marginRight: "10px",
                }}
                src={facebook}
                alt="logo"
              />
            )}
            fields="name,email,picture"
            callback={(res) => responseSocialAuth("facebook", res)}
            cssClass="facebook-btn mt-3 custom-hover"
          />
        </div>

        <span className="mt-3 regular-text">
          By continuing, you agree to{" "}
          <a
            target="_blank"
            href="/general-terms"
            className={`font-semibold no-underline text-[${
              colorTheme || studio_detail?.styles?.header_color || "#60369B"
            }]`}
          >
            {studio_detail?.studio_name} General Terms
          </a>
        </span>
      </div>

      {hideBrowseClasses ? (
        <></>
      ) : (
        <div className="w-full flex justify-center">
          <span
            onClick={() => history.push("/public-class-list")}
            style={{
              color: colorTheme || studio_detail?.styles?.header_color,
            }}
            className="text-center cursor-pointer hover:opacity-50 font-semibold"
          >
            Browse Class
          </span>
        </div>
      )}
    </div>
  );
};

export default StudioLoginForm;
